<template>
  <section class="ihpc-home uk-height-1-1">
    <div class="uk-container uk-height-1-1">
      <div v-if="serviceIsLoaded" class="page uk-height-1-1">
        <PermanentDocument
            class="uk-padding-top uk-margin-large"
            collection-name="texts"
            document-name="home-header"
        ></PermanentDocument>
        <div class="home uk-height-1-1">

          <ColumnDocumentColumnLayout
              data-type="Teaser"
              collection-name="teasers"
          ></ColumnDocumentColumnLayout>

          <div
              class="newsColumn"
              :style="style"
          >
            <h2 class="newsColumnHeader">News</h2>
            <div class="uk-card uk-card-small uk-card-body" v-for="item in news" :key="item.name">
              <h3>{{ item.title }}</h3>
              <vMd :md="item.teaser"/>
            </div>
            <router-link :to="{path: '/storiesAndEvents'}">
              <p class="newsColumnFooter">
                More news
              </p>
            </router-link>
          </div>
        </div>
      </div>

      <div v-else class="uk-margin-auto-vertical">
        <div class="flex-c">
          <span uk-spinner="ratio: 10"></span>
          <p>Loading page documents ...</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PermanentDocument from '@/components/service/document/md/PermanentDocument.vue'
import ColumnDocumentColumnLayout from '@/components/service/collection/ColumnDocumentColumnLayout.vue';
import vMd from '@/components/utilities/vMd.vue'
import {usePermissions} from '@u/usePermissions.js';
import {decode} from '../service/document/lib/parse';
import {computed} from '@vue/reactivity';
import {useStore} from 'vuex';
import {SERVICE_ACRONYM} from '../../../../config';
import {useCollection} from '@u/serviceAssets/useCollection';
import {watch} from "vue";
import {useDarkmode} from "@u/useDarkmode";

export default {
  name: "IHPCHome",
  components: {
    ColumnDocumentColumnLayout,
    PermanentDocument,
    vMd,
  },
  props: {
    serviceName: {
      type: String,
      default: SERVICE_ACRONYM,
    },
    collectionName: {
      type: String,
      default: 'IHPCNews',
      validator: value => value === 'IHPCNews'
    }
  },
  setup(props) {
    const store = useStore()
    const {canEdit} = usePermissions(props)
    const {loadCollection} = useCollection()
    const {darkmode} = useDarkmode()
    const service = computed(() => store.state.service[store.state.serviceName])
    const serviceIsLoaded = computed(() => service.value?.name)

    const news = computed(() => Object.values(store.state.document)
        .filter(doc => doc.dataType === 'IHPCNews')
        .map(doc => decode(doc.content, doc.encoding))
        .filter(item => item.isFeatured)
        .reverse()
    )

    const style = computed(() => ({
      backgroundColor: darkmode.value ? '#e7e7ee' : '#707077'
    }))
    watch(service, service => {
      if (service) {
        loadCollection({collectionName: 'teasers'})
        loadCollection({collectionName: props.collectionName})
      }
    }, {immediate: true})

    return {
      canEdit,
      news,
      serviceIsLoaded,
      style,
    }
  }
}
</script>

<style scoped>
.page {
  padding-top: 3rem;
}

.page .home {
  display: grid;
  padding-bottom: 7rem;
  width: 100%;
  grid-template-columns: 2fr 1fr;
  grid-column-gap: 1rem;
}

.newsColumnHeader,
.newsColumnFooter {
  text-align: center;
}
.newsColumnHeader {
  padding-top: 20px;
  margin: 0;
  font-weight: 400;
  font-size: 2.2rem;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
}
.newsColumnFooter {
  margin-top: 0;
  padding-top: 20px;
  border-top: 5px solid white;
}

.page .flex-c {
  height: 100%;
}
</style>
