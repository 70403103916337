<template>
  <div ref="aceEl"></div>
</template>

<script>
// todo: https://github.com/convergencelabs/ace-collab-ext
// todo: https://convergence.io/

import ace from "brace"
import 'brace/ext/language_tools'

import {
  ref,
  markRaw,
  watch,
  onMounted,
  onBeforeUnmount,
  computed,
} from 'vue'

export default {
  // https://ace.c9.io/#nav=api&api=editor
  name: "VAce",
  emits: [
    "blur",
    "change",
    // "changeAnnotation",
    // "changeSelectionStyle",
    // "changeSession",
    "copy",
    "focus",
    "init",
    "input",
    "paste",
  ],
  props: {
    // File options
    serviceId: {
      type: String,
      required: true,
    },
    collectionId: {
      type: String,
      required: true,
    },
    documentId: {
      type: String,
      required: true,
    },
    // ACE props
    autoScrollEditorIntoView: {
      type: Boolean,
      default: true
    },
    lang: {
      type: String,
      default: "javascript"
    },
    maxLines: {
      type: Number,
      default: 200
    },
    minLines: {
      type: Number,
      default: 1
    },
    options: {
      type: Object,
      default: () => { return {}}
    },
    printMargin: {
      type: Boolean,
      default: true
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    showLineNumbers: {
      type: Boolean,
      default: true,
    },
    theme: {
      type: String,
      default: "chrome"
    },
    useWorker: {
      type: Boolean,
      default: true
    },
    value: {
      type: String,
      required: true
    },
    wrap: {
      type: Boolean,
      default: false
    },

  },
  setup(props, { emit }) {
    const aceEl = ref(null)
    let editor = null

    onMounted(() => {
      editor = markRaw(ace.edit(aceEl.value))

      // Set Options
      editor.$blockScrolling = Infinity
      editor.setOptions({
        autoScrollEditorIntoView: props.autoScrollEditorIntoView,
        minLines: props.minLines,
        maxLines: props.maxLines,
        printMargin: props.printMargin,
        readOnly: props.readOnly,
        showLineNumbers: props.showLineNumbers,
        useWorker: props.useWorker,
        wrap: props.wrap,
        ...props.options,
      })

      const language = computed(() => props.lang.toLowerCase())
      const theme = computed(() => props.theme.toLowerCase())

      require('brace/mode/' + language.value)
      require('brace/snippets/' + language.value)
      require('brace/theme/' + theme.value)

      if (language.value === 'html') require('brace/ext/emmet')

      editor.setOptions({
        mode: "ace/mode/" + language.value,
        theme: "ace/theme/" + theme.value,
      })

      editor.setValue(props.value, 1)

      emit("init", editor)
      editor.on('blur', emit.bind(this, 'blur'))
      editor.on("change", () => emit("change", editor.getValue()))
      // editor.on('changeAnnotation', emit.bind(this, 'changeAnnotation'))
      // editor.on('changeSelectionStyle', emit.bind(this, 'changeSelectionStyle'))
      // editor.on('changeSession', emit.bind(this, 'changeSession'))
      editor.on('copy', emit.bind(this, 'copy'))
      editor.on('focus', emit.bind(this, 'focus'))
      editor.on('input', emit.bind(this, 'input'))
      editor.on('paste', emit.bind(this, 'paste'))
    })

    onBeforeUnmount(() => {
      editor.destroy()
      editor.container.remove()
    })

    watch(
        () => props.lang,
        value => editor.getSession().setMode("ace/mode/" + value)
    )
    watch(
        () => props.showLineNumbers,
        value => editor.setOption("showLineNumbers", value)
    )
    watch(
        () => props.maxLines,
        value => editor.setOption("maxLines", value)
    )
    watch(
        () => props.minLines,
        value => editor.setOption("minLines", value)
    )
    watch(
        () => props.options,
        value => editor.setOptions(value)
    )
    watch(
        () => props.printMargin,
        value => editor.setOption("printMargin", value)
    )
    watch(
        () => props.readOnly,
        value => editor.setOption("readOnly", value)
    )
    watch(
        () => props.theme,
        value => editor.setTheme("ace/theme/" + value)
    )
    watch(
        () => props.useWorker,
        value => editor.setOption("useWorker", value)
    )
    watch(
        () => props.wrap,
        value => editor.setOption("wrap", value)
    )

    return {
      editor,
      aceEl,
    }
  },
}
</script>
