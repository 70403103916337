<template>
  <div class="createIHPCNewsItem">
    <form @submit.prevent>
      <fieldset class="uk-fieldset">
        <legend class="uk-legend">News Item</legend>

        <div class="uk-margin">
          <input
              v-model="src"
              class="uk-input"
              type="text"
              placeholder="Image url"
              @change="onSrcChange"
          />
        </div>

        <div class="uk-margin">
          <input
              v-model="title"
              class="uk-input"
              type="text"
              placeholder="Title"
              @change="onTitleChange"
          />
        </div>

        <div class="uk-margin">
          <input
              v-model="date"
              class="uk-input"
              type="text"
              placeholder="Date (dd-mm-yyyy)"
              @change="onDateChange"
          />
        </div>

        <div class="uk-margin uk-form-row">
          <label>
            <input
                v-model="isLocal"
                type="checkbox"
                @change="onIsLocalChange"
            />
            is local news item
          </label>
        </div>

        <div
            v-if="!isLocal"
            class="uk-margin"
        >
          <input
              v-model="link"
              class="uk-input"
              type="text"
              placeholder="Link url"
              @change="onLinkChange"
          />
        </div>

        <div
            class="uk-margin"
            v-if="!isLocal"
        >
          <label>Body</label>
          <vMarkdownEditor :markdown="body" :showEditor="true" @change="onBodyChange"></vMarkdownEditor>
        </div>
        <div class="uk-margin">
          <label>Teaser</label>
          <vMarkdownEditor
              :markdown="teaser"
              :showEditor="true"
              @change="onTeaserChange"
          ></vMarkdownEditor>
        </div>
        <pre v-if="errorMessage" class="uk-alert uk-alert-danger">{{ errorMessage }}</pre>
        <input
            :disabled="!canSubmit"
            class="uk-button uk-button-primary"
            @click="onSubmitNewsItem"
            value="Submit"
        />
      </fieldset>
    </form>
  </div>
</template>
<script>
import {GET_OR_CREATE_DOCUMENT} from "@/store/graph/operations"
import {SERVICE_ACRONYM} from "../../../../config"
import vMarkdownEditor from "../service/document/md/vMarkdownEditor.vue"
import {computed, ref} from 'vue'
import {useUser} from "@u/useUser"
import {useStore} from "vuex"

export default {
  name: 'CreateIHPCNewsItem',
  components: {
    vMarkdownEditor,
  },
  props: {
    serviceName: {
      type: String,
      default: SERVICE_ACRONYM,
    },
    collectionName: {
      type: String,
      default: 'IHPCNews',
      validator: value => value === 'IHPCNews'
    },
  },
  setup(props) {
    const store = useStore()
    const {user} = useUser()
    const collection = computed(() => Object.values(store.state.collection).find(c => c.name === props.collectionName))

    const error = ref([])
    const errorMessage = computed(() => error.value.filter(i => !!i.length).join('\n'))
    const src = ref('')
    const title = ref('')
    const date = ref('')
    const isLocal = ref(false)
    const link = ref('')
    const body = ref('')
    const teaser = ref('')

    const newsItemName = computed(() => {
      let name
      if (isLocal.value) {
        name = title.value
      } else {
        name = [title.value, date.value].join('-')
      }
      return name
    })

    const canSubmit = computed(() => !errorMessage.value.length)

    const onTitleChange = e => {
      const value = e.target.value
      error.value[0] = value ? '' : 'News item title cannot remain empty.'
      title.value = value
    }
    const onDateChange = e => {
      const value = e.target.value
      error.value[1] = value ? '' : 'News item date must be specified.'
      // todo: parse date to validate
      date.value = value
    }
    const onSrcChange = e => {
      const value = e.target.value
      error.value[2] = value ? '' : 'News item image url is empty.'
      src.value = value
    }
    const onTeaserChange = value => {
      error.value[3] = value ? '' : 'News item teaser is empty.'
      teaser.value = value
    }
    const onIsLocalChange = e => {
      const value = e.target.checked
      error.value[4] = ''
      error.value[5] = ''
      if (!value) {
        onBodyChange('')
        onLinkChange({target: {value: ''}})
      }
      isLocal.value = value
    }
    const onBodyChange = value => {
      error.value[4] = value ? '' : 'News item card body cannot be empty for external news.'
      body.value = value
    }
    const onLinkChange = e => {
      const value = e.target.value
      error.value[5] = value ? '' : 'News item link cannot is required for external news items.'
      link.value = value
    }

    const onSubmitNewsItem = () => {
      const linkUrl = isLocal.value ? encodeURIComponent(newsItemName.value) : link.value
      store.dispatch(GET_OR_CREATE_DOCUMENT, {
        serviceName: SERVICE_ACRONYM,
        collectionName: collection.value.name,
        documentName: newsItemName.value,
        encoding: 'json',
        dataType: 'IHPCNews',
        createIfNotExists: true,
        content: JSON.stringify({
          src: src.value,
          title: title.value,
          date: date.value,
          isLocal: isLocal.value,
          link: linkUrl,
          body: body.value,
          teaser: teaser.value,
          isFeatured: true,
        }),
      })
          .then(() => {
            src.value = ''
            title.value = ''
            link.value = ''
            isLocal.value = false
            teaser.value = ''
            body.value = ''
            date.value = ''
          })
    }

    return {
      body,
      canSubmit,
      errorMessage,
      date,
      isLocal,
      link,
      src,
      teaser,
      title,
      onBodyChange,
      onDateChange,
      onIsLocalChange,
      onLinkChange,
      onSrcChange,
      onSubmitNewsItem,
      onTeaserChange,
      onTitleChange,
      user,
    }
  }
}
</script>
<style scoped>
[disabled] {
  background-color: grey !important;
}

.newsItem .uk-card {
  overflow: hidden;
  border-radius: 0.1rem;
}

.newsItem.card {
  cursor: pointer;
}

.card img {
  min-width: 100% !important;
}
</style>
