<template>
  <LLeftDrawerRow
      v-if="CHCAAUCloud"
      class="centerService"
      :show-drawer="showImageDetails"
      uk-height-match
  >
    <template
        #drawer
        v-if="CHCAAUCloud.zoomableImage"
    >
      <div class="uk-card-default uk-card-body uk-background-muted">
        <ZoomableImageSettings
            v-if="CHCAAUCloud"
            :document-name="CHCAAUCloud.zoomableImage"
            :create-if-not-exists="true"
        ></ZoomableImageSettings>
      </div>
    </template>
    <template #body>
      <div class="uk-card uk-card-default uk-width-1-1">
        <LSquareLeftRow>
          <template #square>
            <Passepartout v-if="CHCAAUCloud.zoomableImage">
              <ZoomableImage
                  :document-name="CHCAAUCloud.zoomableImage"
                  @click="toggleImageEditor"
              ></ZoomableImage>
            </Passepartout>
          </template>
          <template #body>
            <div class="flex-c">
              <h3
                  class="uk-card-title uk-margin-remove-bottom uk-text-right"
                  @click.stop=""
              >
                <editableDiv
                    :is-editable="canEdit"
                    placeholder="CHCAA UCloud Service Title"
                    :value="CHCAAUCloud.title"
                    @change="value => updateAttribute('title', value)"
                ></editableDiv>
              </h3>
              <p
                  class="uk-text-small uk-margin-remove-top uk-text-right"
                  @click.stop=""
              >
                <editableDiv
                    :is-editable="canEdit"
                    placeholder="Optional Subtitle"
                    :value="CHCAAUCloud.subtitle"
                    @change="value => updateAttribute('subtitle', value)"
                ></editableDiv>
              </p>
            </div>
          </template>
        </LSquareLeftRow>

        <div class="uk-card-body">
          <MarkdownContent
              :collection-id="collectionId"
              :document-id="documentId"
              field-path="body"
          ></MarkdownContent>
        </div>
      </div>
    </template>
  </LLeftDrawerRow>
  <div
      v-else
      class="uk-placeholder"
  >
    Loading centerService...
  </div>
</template>

<script>
import EditableDiv from "@/components/utilities/EditableDiv";
import LSquareLeftRow from "@/components/layout/LSquareLeftRow";
import LLeftDrawerRow from "@/components/layout/LLeftDrawerRow";
import MarkdownContent from "@/components/service/document/md/MarkdownContent";
import Passepartout from "@/components/image/Passepartout"
import ZoomableImage from "@/components/image/zoomableImage/ZoomableImage";
import ZoomableImageSettings from "@/components/image/zoomableImage/ZoomableImageSettings";
import {ref} from "vue";
import {usePermissions} from "@/components/use/usePermissions";
import {useVersion} from "@/components/use/serviceAssets/useVersion";
import {useCHCAAUCloudService} from "@u/serviceAssets/documentEditors/CHCAAUCloudService";
import {useStore} from "vuex";

export default {
  name: "CHCAAUCloudService",
  components: {
    EditableDiv,
    LLeftDrawerRow,
    LSquareLeftRow,
    MarkdownContent,
    Passepartout,
    ZoomableImage,
    ZoomableImageSettings,
  },
  props: {
    collectionId: {
      type: String,
      required: true,
    },
    documentId: {
      type: String,
      required: true,
    }
  },
  setup(props) {
    const store = useStore()
    const {canEdit} = usePermissions(props)
    const {CHCAAUCloud} = useCHCAAUCloudService(props)
    const {patchVersionPayloadAttribute} = useVersion()

    const showImageDetails = ref(false)
    const toggleImageEditor = () => showImageDetails.value = !showImageDetails.value && canEdit.value

    const updateAttribute = (attributeName, value) =>
        patchVersionPayloadAttribute({
          serviceId: store.state.serviceName,
          collectionId: props.collectionId,
          documentId: props.documentId,
          attributeName,
          value
        })
    return {
      // getDocumentTemplate,
      canEdit,
      CHCAAUCloud,
      showImageDetails,
      toggleImageEditor,
      updateAttribute,
    }
  }
}
</script>

<style scoped lang="stylus">
.flex-c
  display flex
  flex-direction column
  justify-content center
  height 100%
</style>
