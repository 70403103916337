<template>
  <div class="compProps">
<p>Properties</p>
    <div
        class="uk-grid uk-grid-collapse"
        uk-grid
    >
      <div
          class="uk-grid uk-width-1-1@s"
          uk-grid
          v-for="(value, name) in props"
          :key="name"
      >
        <div class="uk-margin-left uk-text-left uk-width-1-6@s">
          {{ name }}
        </div>
        <div class="uk-text-center uk-width-expand@s">
          {{ value }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
name: "ComponentPropList",
  props: {
    props: Object,
  }
}
</script>

<style scoped>

</style>
