<template>
  <section class="uk-height-1-1">
    <div class="uk-container uk-height-1-1">
      <div
          class="banner uk-width-1-1"
      ></div>
      <div
          v-if="service"
          class="page uk-height-1-1"
      >
        <PermanentDocument
            class="uk-padding-top uk-margin-large"
            collection-name="texts"
            document-name="home-header"
        ></PermanentDocument>
        <div class="home uk-height-1-1">

          <ColumnDocumentColumnLayout
              data-type="Teaser"
              collection-name="teasers"
          ></ColumnDocumentColumnLayout>

          <DocumentColumnLayout
              dataType="MarkdownContent"
              collection-name="news"
              encoding="markdown"
              create-button-label="Add News Item"
              no-documents-type-label="News"
              :isInverted="true"
          ></DocumentColumnLayout>

        </div>
      </div>

      <div
          v-else
          class="uk-margin-auto-vertical"
      >
        <div class="flex-c">
          <h1>Home</h1>
          <p>
            Loading page documents ...
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PermanentDocument from "@/components/service/document/md/PermanentDocument"
import DocumentColumnLayout from "@/components/service/collection/DocumentColumnLayout"
import ColumnDocumentColumnLayout from "@/components/service/collection/ColumnDocumentColumnLayout";
import {usePermissions} from "@/components/use/usePermissions";
import {computed} from "vue";
import {useStore} from "vuex";

export default {
  name: "Home",
  components: {
    ColumnDocumentColumnLayout,
    DocumentColumnLayout,
    PermanentDocument,
  },
  setup(props) {
    const store = useStore()
    const {canEdit} = usePermissions(props)
    const service = computed(() => store.state.service[store.state.serviceName])

    return {
      canEdit,
      service,
    }
  }
}
</script>

<style lang="stylus" scoped>
.banner
  min-height: 300px!important
  margin 1rem 0
  background: url(https://github.com/centre-for-humanities-computing/websiteMedia/raw/main/images/CEDHAR/cedhar_banner.png)
  background-position center
  background-size cover
.page
  padding-top 3rem

  .home
    display: grid
    width 100%
    grid-template-columns 2fr 1fr
    grid-column-gap 1rem

    .flex-c
      height 100%

</style>
