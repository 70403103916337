<template>
  <section class="ihpcNews uk-section">
    <div class="uk-container">
      <PermanentDocument collectionName="texts" documentName="Stories-and-Events-header"></PermanentDocument>
      <div class="uk-card uk-card-body">
        <button
            v-if="user"
            class="uk-button uk-button-default"
            @click="showEditor = !showEditor"
        >Add News Item
        </button>
        <CreateIHPCNewsItem
            v-if="showEditor"
            :serviceName="serviceName"
            :collectionName="collectionName"
        ></CreateIHPCNewsItem>
      </div>
      <LThreeColumns>
        <template #default>
          <IHPCNewsItem
              v-for="(item, index) in list"
              :key="index"
              class="uk-grid-match"
              :serviceName="serviceName"
              :collectionName="collectionName"
              :documentName="item.name"
          ></IHPCNewsItem>
        </template>
      </LThreeColumns>
    </div>
  </section>
</template>
<script>
import {computed, ref} from "@vue/reactivity"
import {useStore} from "vuex"
import {SERVICE_ACRONYM} from "../../../../config"
import LThreeColumns from "../layout/LThreeColumns.vue"
import CreateIHPCNewsItem from "@c/IHPC/CreateIHPCNewsItem";
import IHPCNewsItem from "./IHPCNewsItem.vue"
import {useCollection} from "@u/serviceAssets/useCollection.js"
import {useUser} from "@u/useUser"
import {watch} from "vue"
import PermanentDocument from "../service/document/md/PermanentDocument.vue"

export default {
  name: 'IHPCNews',
  components: {
    CreateIHPCNewsItem,
    IHPCNewsItem,
    LThreeColumns,
    PermanentDocument,
  },
  props: {
    serviceName: {
      type: String,
      default: SERVICE_ACRONYM,
    },
    collectionName: {
      type: String,
      default: 'IHPCNews',
      validator: value => value === 'IHPCNews'
    }
  },
  setup(props) {
    const {user} = useUser()
    const store = useStore()
    const {loadCollection} = useCollection()
    const service = computed(() => store.state.service[store.state.serviceName])

    const showEditor = ref(false)
    const list = computed(() => Object.values(store.state.document)
        .filter(doc => doc.dataType === props.collectionName)
        .sort((a, b) => a.created > b.created)
        .reverse()
    )

    // .sort((a, b) => {
    //     const getDate = doc = decode(doc.content, doc.encoding).date
    //     return getDate(a) - getDate(b)
    // })

    watch(service, serviceIsLoaded => {
      if (serviceIsLoaded) loadCollection({collectionName: props.collectionName})
    }, {immediate: true})

    return {
      list,
      showEditor,
      user,
    }
  }
}
</script>
