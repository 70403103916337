<template>
  <section class="ucloudServices uk-section">
    <div class="uk-container">
      <PermanentDocument
          v-if="service"
          class="uk-margin"
          collection-name="texts"
          document-name="UCloud-services-page-header"
      ></PermanentDocument>
      <DocumentColumnLayout
          v-if="service"
          dataType="CHCAAUCloudService"
          collection-name="CHCAAuCloudServices"
          create-button-label="Add CHCAA UCloud Service"
          no-documents-type-label="CHCAA UCloud Service"
          :default-document-content="defaultDocumentContent"
          :isInverted="true"
      ></DocumentColumnLayout>
      <vLoading
          v-else
          resource-name="CHCAA UCloud Service"
      ></vLoading>
    </div>
  </section>
</template>

<script>
import DocumentColumnLayout from "@/components/service/collection/DocumentColumnLayout"
import PermanentDocument from "@/components/service/document/md/PermanentDocument";
import {computed} from "vue";
import {useStore} from "vuex";
import {useDocumentTemplate} from "@u/serviceAssets/documentEditors/useDocumentTemplate";

export default {
  name: "UCloudServices",
  components: {
    DocumentColumnLayout,
    PermanentDocument,
  },
  setup() {
    const store = useStore()
    const {getDocumentTemplate} = useDocumentTemplate()
    const service = computed(() => store.state.service[store.state.serviceName])
    const defaultDocumentContent = getDocumentTemplate('CHCAAUCloudService').content

    return {
      defaultDocumentContent,
      service,
    }
  }
}
</script>

<style scoped>

</style>
