<template>
  <div class="newsItem">
    <div v-if="newsItem" @click="navigate()" class="newsItem card uk-card uk-card-default"
         :class="{'uk-card-hover': darkmode}"
    >
      <div class="">
        <IconPencil
            v-if="user"
            @click="onToggleEditors"
        ></IconPencil>

        <img :data-src="newsItem.src" uk-img/>
        <EditableDiv
            v-if="showFieldEditors"
            :is-editable="true"
            placeholder="url"
            :value="newsItem.src"
            @change="value => onPatchAttribute('src', value)"
        ></EditableDiv>
        <div class="uk-card-body">
          <div class="uk-flex uk-flex-end">
            <em
                class=" uk-button-primary uk-text-white uk-text-small uk-margin-remove"
                style="padding: 0 10px"
                @click.stop=""
            >
              <EditableDiv
                  :is-editable="showFieldEditors"
                  placeholder="date"
                  :value="newsItem.date"
                  @change="value => onPatchAttribute('date', value)"
              ></EditableDiv>
            </em>
          </div>
          <h3 class="uk-margin-top uk-text-left">
            <EditableDiv
                :is-editable="showFieldEditors"
                placeholder="News Item Title"
                :value="newsItem.title"
                @change="value => onPatchAttribute('title', value)"
            ></EditableDiv>
          </h3>
          <div
              v-if="showFieldEditors"
              class="uk-margin uk-grid-small uk-child-width-auto uk-grid"
          >
            <label>
              <input
                  class="uk-checkbox"
                  type="checkbox"
                  v-model="newsItem.isFeatured"
                  @click.stop="() => onPatchAttribute('body', !newsItem.isFeatured)"
              />
              Feature
            </label>
          </div>
          <vMarkdownInput
              v-if="showFieldEditors"
              class="noNav"
              :value="newsItem.body"
              @change="value => onPatchAttribute('body', value)"
          ></vMarkdownInput>
          <vMd
              v-if="!showFieldEditors"
              class="noNav uk-text-justify"
              :showEditor="false"
              :md="previewBody"
          ></vMd>
          <div v-if="showFieldEditors">
            <DeleteDocumentDialog @delete-document-confirmed="onDelete"></DeleteDocumentDialog>
            <div class="uk-margin">
              <button
                  class="uk-button uk-button-primary"
                  v-if="user"
                  @click.stop="navigate(true)"
              >Navigate to Article
              </button>
            </div>
            <hr>
            <label>
              <span class="uk-text-danger">teaser text (only displayed in news column)</span>
              <vMarkdownEditor
                  class="noNav"
                  :showEditor="showFieldEditors"
                  :markdown="newsItem.teaser"
                  @change="value => onPatchAttribute('teaser', value)"
              ></vMarkdownEditor>
            </label>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import vMarkdownInput from "@c/service/document/md/vMarkdownInput";
import EditableDiv from "@c/utilities/EditableDiv";
import {SERVICE_ACRONYM} from "../../../../config"
import vMarkdownEditor from "../service/document/md/vMarkdownEditor.vue"
import DeleteDocumentDialog from "../service/document/DeleteDocumentDialog.vue"
import {useDocument} from "@u/serviceAssets/useDocument"
import {useVersion} from "@u/serviceAssets/useVersion"
import {computed, ref, watch} from 'vue'
import {useUser} from "@u/useUser"
import {useStore} from "vuex"
import {decode} from "../service/document/lib/parse"
import {useCollection} from "@u/serviceAssets/useCollection";
import {useRouter} from "vue-router";
import {useDarkmode} from "@u/useDarkmode";

export default {
  name: 'IHPCNewsItem',
  components: {
    DeleteDocumentDialog,
    EditableDiv,
    vMarkdownEditor,
    vMarkdownInput,
  },
  props: {
    serviceName: {
      type: String,
      default: SERVICE_ACRONYM,
    },
    collectionName: {
      type: String,
      default: 'IHPCNews',
      validator: value => value === 'IHPCNews'
    },
    documentName: {
      type: String
    },
  },
  setup(props) {
    const router = useRouter()
    const store = useStore()
    const {loadCollection} = useCollection()
    const {loadDocument} = useDocument(props)
    const {deleteVersion, patchVersionPayloadAttribute} = useVersion()
    const {user} = useUser()
    const {darkmode} = useDarkmode()
    const service = computed(() => store.state.service[store.state.serviceName])
    const collection = computed(() => Object.values(store.state.collection).find(c => c.name === props.collectionName))
    const document = computed(() => {
      let document
      if (props.documentName) document = Object.values(store.state.document).find(c => c.name === props.documentName)
      return document
    })
    const newsItem = computed(() => {
      let payload
      if (document.value?.repoId) payload = decode(document.value.content, document.value.encoding)
      return payload
    })

    const showFieldEditors = ref(false)

    const rx_tags = /(<([^>]+)>)/ig

    const previewBody = computed(() => {
      let body
      if (newsItem.value) body = newsItem.value.body
          .replaceAll(rx_tags, '')
          .substring(0, 280)
          .trim() + '...'
      return body
    })


    const navigate = override => {
      if (override || !showFieldEditors.value) {
        const {isLocal, link} = newsItem.value
        if (isLocal) openLocalNewsLink(link)
        else openRemoteNewsLink(link)
      }
    }
    const openRemoteNewsLink = url => window.open(url, '_blank')

    const openLocalNewsLink = url => {
      url = '/localNews/' + newsItem.value.link
      router.push(url)
    }
    const onDelete = () => deleteVersion({
      serviceId: props.serviceName,
      collectionId: collection.value.repoId,
      documentId: document.value.repoId,
    })
    const onToggleEditors = () => showFieldEditors.value = !showFieldEditors.value

    const onPatchAttribute = (attributeName, value) => patchVersionPayloadAttribute({
      serviceId: props.serviceName,
      collectionId: collection.value.repoId,
      documentId: document.value.repoId,
      attributeName,
      value
    })

    watch(service, service => {
      if (service) loadCollection({collectionName: props.collectionName})
    }, {immediate: true})

    watch(collection, collection => {
      if (collection && props.documentName) loadDocument({
        serviceName: props.serviceName,
        collectionName: props.collectionName,
        documentName: props.documentName
      }, 'IHPCNewsItem.vue')
    }, {immediate: true})

    return {
      darkmode,
      navigate,
      newsItem,
      previewBody,
      onDelete,
      onToggleEditors,
      onPatchAttribute,
      showFieldEditors,
      user,
    }
  }
}
</script>
<style scoped>
[disabled] {
  background-color: grey !important;
}

.newsItem .uk-card {
  overflow: hidden;
  border-radius: 0.1rem;
}

.newsItem.card {
  cursor: pointer;
}

.card img {
  min-width: 100% !important;
}
</style>
