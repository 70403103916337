<template>
  <div class="ihpcLocalNewsItem uk-section">
    <div class="uk-container">

      <div class="responsive grid">
        <LTwoToOne>
          <template #two>

            <h1>{{ localNewsitemAlias }}</h1>
            <MarkdownContent
                v-if="collection && document"
                :collection-id="collection.repoId"
                :document-id="document.repoId"
                fieldPath="body"
            ></MarkdownContent>

          </template>
          <template #one>

            <div
                class="newsColumn uk-margin-left"
                :style="style"
            >
              <h1 class="newsColumnHeader">News</h1>
              <div class="uk-card uk-card-small uk-card-body" v-for="item in news" :key="item.name">
                <h3>{{ item.title }}</h3>
                <vMd :md="item.teaser"/>
              </div>
              <router-link :to="{path: '/storiesAndEvents'}">
                <p class="newsColumnFooter">
                  More news
                </p>
              </router-link>
            </div>

          </template>
        </LTwoToOne>
      </div>
    </div>
  </div>
</template>

<script>
import {computed} from "@vue/reactivity";
import {decode} from "@c/service/document/lib/parse";
import {watch} from "vue";
import {useCollection} from "@u/serviceAssets/useCollection";
import {useStore} from "vuex";
import {usePermissions} from "@u/usePermissions";
import MarkdownContent from "@c/service/document/md/MarkdownContent";
import {SERVICE_ACRONYM} from "../../../../config";
import {useDocument} from "@u/serviceAssets/useDocument";
import {useDarkmode} from "@u/useDarkmode";

export default {
  name: "IHPCLocalNewsItem.vue",
  components: {
    MarkdownContent,
  },
  props: {
    serviceName: {
      type: String,
      default: SERVICE_ACRONYM,
    },
    collectionName: {
      type: String,
      default: 'IHPCNews',
      validator: value => value === 'IHPCNews'
    },
    localNewsitemAlias: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const store = useStore()
    const {canEdit} = usePermissions(props)
    const {getCollectionId, loadCollection} = useCollection()
    const {getDocument} = useDocument()
    const {darkmode} = useDarkmode()

    const service = computed(() => store.state.service[store.state.serviceName])
    const collection = computed(() => store.state.collection[getCollectionId(props.collectionName)])
    const document = computed(() => getDocument(props.localNewsitemAlias))

    const news = computed(() => Object.values(store.state.document)
        .filter(doc => doc.dataType === 'IHPCNews')
        .map(doc => decode(doc.content, doc.encoding))
        .filter(item => item.isFeatured)
        .reverse()
    )
    const style = computed(() => ({
      backgroundColor: darkmode.value ? '#e7e7ee' : '#707077'
    }))

    watch(service, service => {
      if (service) {
        loadCollection({collectionName: props.collectionName})
      }
    }, {immediate: true})

    watch(collection, collection => {
      if (collection) {
        loadCollection({
          collectionName: props.collectionName
        })
      }
    }, {immediate: true})

    return {
      canEdit,
      collection,
      document,
      news,
      style,
    }
  }
}
</script>

<style scoped>
.newsColumn {
  padding: 0.9rem 1.1rem;
}
</style>
