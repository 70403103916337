<template>
  <div
      class="uk-width-1-1 uk-full-height footer"
      v-if="partners.length"
  >
    <Partners :partners="partners"></Partners>
  </div>
</template>

<script>
import Partners from "@/components/site/Partners";
import {PRIMARY_BACKGROUND_COLOR} from '../../../config'

export default {
  name: "Footer",
  components: {
    Partners,
  },
  props: {
    partners: {
      type: Array,
      default: () => []
    }
  },
  setup() {
    return {
      PRIMARY_BACKGROUND_COLOR
    }
  }
}
</script>

<style scoped>
.footer {
  background-color: v-bind(PRIMARY_BACKGROUND_COLOR) !important;
  color: var(--navbarColor) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1vh;
}
</style>
