<template>
  <div class="uk-margin">
    <input
        :value="image.src"
        :disabled="disabled"
        class="uk-input"
        placeholder="Image-src"
        type="text"
        @input="updateSrc"
    >
  </div>
  <div class="uk-margin">
    <input
        :disabled="disabled"
        :value="image.zoom"
        class="uk-range"
        max="5"
        min=".5"
        step=".125"
        type="range"
        uk-tooltip="Image zoom"
        @input="updateZoom"
    >
  </div>
  <div class="uk-margin">
    <input
        :disabled="offsetVerticalIsDisabled"
        :value="image.offsetVertical"
        :max="image.offsetVerticalMax"
        :min="image.offsetVerticalMin"
        class="uk-range"
        step="0.25"
        type="range"
        uk-tooltip="Vertical position"
        @input="updateOffsetVertical"
    >
  </div>
  <div class="uk-margin">
    <input
        :disabled="offsetHorizontalIsDisabled"
        :value="image.offsetHorizontal"
        :max="image.offsetHorizontalMax"
        :min="image.offsetHorizontalMin"
        class="uk-range"
        step="0.25"
        type="range"
        uk-tooltip="Horizontal position"
        @input="updateOffsetHorizontal"
    >
  </div>
</template>

<script>
import {useZoomableImage} from "@/components/use/serviceAssets/documentEditors/ZoomableImage";
import {usePermissions} from "@/components/use/usePermissions";
import {computed} from "vue";

export default {
  name: "ZoomableImageSettings",
  props: {
    collectionName: {
      type: String,
      default: 'zoomableImage',
      validator: value => value === 'zoomableImage',
    },
    documentName: {
      type: String,
      required: true,
    },
    createIfNotExists: {
      type: Boolean,
      default: true,
    }
  },
  setup(props) {
    const {canEdit} = usePermissions(props)
    const {imageConfiguration, loadZoomableImage,  updateSrc, updateZoom, updateOffsetHorizontal, updateOffsetVertical} = useZoomableImage(props)
    const disabled = computed(() => !canEdit.value || !imageConfiguration.value?.repoId)

    const offsetVerticalIsDisabled = computed(() => !!(disabled.value || imageConfiguration.value.offsetVerticalMin === imageConfiguration.value.offsetVerticalMax))
    const offsetHorizontalIsDisabled = computed(() => !!(disabled.value || imageConfiguration.value.offsetHorizontalMin === imageConfiguration.value.offsetHorizontalMax))
    loadZoomableImage()

    return {
      image: imageConfiguration,
      updateOffsetHorizontal,
      updateOffsetVertical,
      updateSrc,
      updateZoom,
      offsetVerticalIsDisabled,
      offsetHorizontalIsDisabled,
      disabled,
    }
  }
}
</script>

<style scoped lang="stylus">
</style>
