<template>
  <img
      :class="{invert: darkmode && darkmodeInvert}"
      :style="image.style"
      :src="image.src"
      @click.stop="$emit('click')"
  >
</template>

<script>

import {useZoomableImage} from "@/components/use/serviceAssets/documentEditors/ZoomableImage";
import {useDarkmode} from "@/components/use/useDarkmode";

export default {
  name: "ZoomableImage",
  emits: ['click'],
  props: {
    collectionName: {
      type: String,
      default: 'zoomableImage',
      validator: value => value === 'zoomableImage',
    },
    documentName: {
      type: String,
      required: true,
    },
    darkmodeInvert: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { darkmode } = useDarkmode()
    const {loadZoomableImage, imageConfiguration} = useZoomableImage(props)

    loadZoomableImage()

    return {
      darkmode,
      image: imageConfiguration,
    }
  }
}
</script>

<style scoped lang="stylus">
  img
    position: relative
    min-width 100%

</style>
