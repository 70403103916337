<template>
  <div class="passepartout circular-frame">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Passepartout"
}
</script>

<style scoped lang="stylus">
  .circular-frame
    display inline-block
    position relative
    width 100px
    height 100px
    overflow hidden
    border-radius 50%


  .circular-frame>img
    width auto
    height 100%

</style>
